<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import errorHandler from '@/helpers/errorHandler';
import { required, integer } from 'vuelidate/lib/validators'

/**
 * Starter component
 */
export default {
  page: {
    title: `Lecture Form`,
  },
  data() {
    return {
      title: "Lecture",
      items: [
        {
          text: "Work center",
          href: "/",
        },
        {
          text: "Lectures",
          href: "/chapters",
        },
        {
          text: "Edits",
          active: true,
        },
      ],
      submit: false,
      backendError: false,
      type: null,
      form:{
        name: "",
        year: null,
        price: 0,
        description: null,
        id: null,
        give_chance: null,
      },
      valuse:[{text:'Yes',value:true}, {text:'No',value:false}],
      yearError: null,
    };
  },
  computed:{
    years(){
      return this.$store.getters['option/years'];
    },
  },
  components: {
    Layout,
    PageHeader,
  },
  validations:{
    form:{
      name: {
        required,
      },
      price:{
        integer
      },
      year:{
        required,
      },
    }
  },
  methods:{
    validate(){
      if(this.form.year){
        this.yearError = true;
      }else{
        this.yearError = false;
      }
      return this.yearError;
    },
    clearSelectError2(){
      this.yearError = null;
    },
    async handleSubmit() {
      this.submit = true;
      this.$v.$touch();
      let check = await this.validate();
      if(!this.$v.$anyError && check){
        this.$store.dispatch("chapter/addChapter",this.form).then(
           ()=>{
            errorHandler.methods.successful(`${this.form.name} has been created successfully!`)
            this.$router.push('/chapters');
          }
        ).catch((err)=>{
          errorHandler.methods.error(err);
        });
      }
    },
    async handleUpdate(){
      this.submit = true;
      this.$v.$touch();
      let check = await this.validate();
      if(!this.$v.$anyError && check){
        this.$store.dispatch("chapter/editChapter",{...this.form,id:this.$route.params.id}).then(
           ()=>{
            errorHandler.methods.successful(`${this.form.name} has been created successfully!`)
            this.$router.push('/chapters');
          }
        ).catch((err)=>{
          errorHandler.methods.error(err);
        });
      }
    },
  },
  async created(){
    await this.$store.dispatch('option/getYearOption').then( ()=>{}).catch( err => {
        errorHandler.methods.errorMessage(err);
      });
    if(this.$route.params.id){
      this.type = 'edit';
      await this.$store.dispatch('chapter/getChapterInfo',this.$route.params.id).then( res =>{
        this.form.name = res.name;
        this.form.price = res.price;
        this.form.year = res.educational_year.id;
        this.form.description = res.description || '';
        this.form.give_chance = res.give_chance;
      }).catch(err =>{
        errorHandler.methods.errorMessage(err);
      })
    }else{
      this.type = 'create';
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div>
                <div>
                  <div>
                    <h2 v-if="type == 'create'">Create Lecture</h2>
                    <h2 v-if="type == 'edit'">Edit Lecture</h2>
                    <hr>
                  </div>
                <form action="#" @submit.prevent="">
                <div class="row">
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Lecture Name:"
                        label-for="input-D"
                    >
                        <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.name.$error }" placeholder="Enter chapter name" />
                        <div v-if="submit && $v.form.name.$error" class="invalid-feedback">
                            <span v-if="!$v.form.name.required">This name is required.</span>
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6">
                    <b-form-group
                        id="input-group-2"
                        label="Price:"
                        label-for="input-D"
                    >
                        <input v-model="form.price" type="number" min="1" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.price.$error }" placeholder="Enter chapter price" />
                        <div v-if="submit && $v.form.price.$error" class="invalid-feedback">
                            <span v-if="!$v.form.price.required">This Price is required.</span>
                        </div>
                        <div v-if="submit && $v.form.price.$error" class="invalid-feedback">
                            <span v-if="!$v.form.price.integer">This Price should be positive integar.</span>
                        </div>
                    </b-form-group>
                    </div>
                    <div class="col-md-6" >
                      <b-form-group label="Stage:" label-for="input-C" >
                        <b-form-select  v-if="type == 'create' " v-model="form.year" :options="this.years" :state="yearError" @change="clearSelectError2"></b-form-select>
                        <b-form-select  v-if="type == 'edit' && form.year " v-model="form.year" :options="this.years" :state="yearError" @change="clearSelectError2"></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-md-6" >
                      <b-form-group label="Give Chance:" label-for="input-C" >
                        <b-form-select  v-if="type == 'create' " v-model="form.give_chance" :options="this.valuse" ></b-form-select>
                        <b-form-select  v-if="type == 'edit' && form.give_chance != null " v-model="form.give_chance" :options="this.valuse" ></b-form-select>
                        <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-md-12">
                    <b-form-group
                        id="input-group-phone"
                        label="Note:"
                        label-for="input-phone"
                    >
                        <div class="row">
                        <div class="col-xl-12">
                            <b-input-group>
                            <textarea v-if=" type == 'create' " v-model="form.description"  name="description" class="form-control"  placeholder="Enter description" />
                            <textarea v-if=" type == 'edit' && form.description != null" v-model="form.description"  name="description" class="form-control" placeholder="Enter description" />
                            </b-input-group>
                        </div>
                        </div>
                    </b-form-group>
                    </div>
                </div>
                </form>
                </div>
                <hr />
                <div class="float-right">
                <button class="btn btn-outline-success" type="submit" @click="handleSubmit" v-if="type == 'create'">Submit</button>
                <button class="btn btn-outline-success" type="submit" @click="handleUpdate" v-if="type == 'edit'">Update</button>
                <b-button variant="outline-danger" class="ml-2 aline-right" @click="$router.go(-1)"
                >Cancel</b-button
                >
                </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#input-group-1 {
  display: inline;
}
</style>